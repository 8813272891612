<template>
  <v-card>
    <v-card-title>
      Prescription Status
    </v-card-title>
    <v-card-text>
      <v-timeline
        dense
        class="timeline-custom-dense timeline-custom-dots card-content"
      >
        <v-timeline-item
          v-for="(item, index) in items"
          :key="index"
          small
          color="primary"
        >
          <v-card :color="item.color">
            <v-card-title>
              <span class="text-base white--text">
                <v-icon
                  v-if="item.status"
                  dark
                  right
                  color="error"
                >
                  {{ icons.mdiCommentAlert }}
                </v-icon>  {{ item.title }}</span>
              <v-spacer></v-spacer>
              <small class="text-xs white--text text-no-wrap">{{ item.subtitle }} 0{{ index+1 }}</small>
            </v-card-title>
            <v-card-text class="pt-4">
              <div class="d-flex justify-space-between align-center">
                <div>
                  <p class="mb-0">
                    {{ item.content }}
                  </p>
                </div>
                <v-divider
                  v-if="item.buttonText"
                  :vertical="$vuetify.breakpoint.mdAndUp"
                ></v-divider>
                <div
                  v-if="item.buttonText"
                  class="tw-pl-4"
                >
                  <v-btn
                    color="primary"
                    dark
                    :to="{ name: item.to}"
                  >
                    {{ item.buttonText }}
                    <v-icon
                      dark
                      right
                    >
                      {{ icons.mdiCheckboxMarkedCircleOutline }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
    mdiArrowRight,
    mdiMessageTextOutline,
    mdiPhone,
    mdiCheckboxMarkedCircleOutline,
    mdiCommentAlert,
} from '@mdi/js'
import useVuetify from '@core/utils/vuetify'

export default {
    name: 'RxTimeline',
    setup() {
        const { rootThemeClasses } = useVuetify()

        return {
            rootThemeClasses,
            icons: {
                mdiCheckboxMarkedCircleOutline,
                mdiArrowRight,
                mdiMessageTextOutline,
                mdiPhone,
                mdiCommentAlert,
            },
        }
    },
    data() {
        return {
            items: [
                {
                    title: 'Patient Input Required',
                    status: 'patient-input-required',
                    color: 'primary',
                    colorInDark: '#ff4081',
                    subtitle: 'Step:',
                    content:
            'Do you want your free Doctor-selected skincare samples & free prescription delivery? Please click here to get started!',
                    to: 'enrollment',
                    buttonText: 'Get Started',
                },
                {
                    title: 'Processing Prescription',

                    //   status: 'patient-input-required',
                    color: 'grey',
                    colorInDark: '#ff4081',
                    subtitle: 'Step:',
                    content:
            'Pharmacy is currently processing your prescription. This includes charing your insurance and making sure the medication is safe to use.',
                    to: 'enrollment',
                    buttonText: null,
                },
                {
                    title: 'Payment Required',

                    //   status: 'patient-input-required',
                    color: 'grey',
                    colorInDark: '#ff4081',
                    subtitle: 'Step:',
                    content:
            'Pharmacy is currently processing your prescription. This includes charing your insurance and making sure the medication is safe to use.',
                    to: 'enrollment',
                    buttonText: null,
                },
                {
                    title: 'Shipping Prescription',

                    //   status: 'patient-input-required',
                    color: 'grey',
                    colorInDark: '#ff4081',
                    subtitle: 'Step:',
                    content:
            'Pharmacy is currently processing your prescription. This includes charing your insurance and making sure the medication is safe to use.',
                    to: 'enrollment',
                    buttonText: null,
                },
                {
                    title: 'Delivered',

                    //   status: 'patient-input-required',
                    color: 'grey',
                    colorInDark: '#ff4081',
                    subtitle: 'Step:',
                    content:
            'Pharmacy is currently processing your prescription. This includes charing your insurance and making sure the medication is safe to use.',
                    to: 'enrollment',
                    buttonText: null,
                },
            ],
        }
    },
}
</script>
