<!-- eslint-disable import/no-unresolved -->
<!-- eslint-disable import/extensions -->
<!-- eslint-disable import/no-unresolved -->
<!-- eslint-disable import/no-unresolved -->
<!-- eslint-disable import/extensions -->
<template>
  <div class="tw-pb-6">
    <v-row>
      <v-col
        md="12"
        cols="12"
      >
        <v-card>
          <v-tabs
            v-model="tab"
            class="tab-index"
          >
            <v-tab
              v-for="item in items"
              :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
              class="mt-2"
            >
              <v-card-title>
                Visit Details
              </v-card-title>
              <v-card-text>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title> Patient Name: <strong>{{ visitData[0].patientData.name }}</strong></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Visit Date: <strong>{{ visitData[0].visitData.rxDate }}</strong></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title> Visit ID: <strong>{{ visitData[0].visitData.id }}</strong></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-tab-item>
            <v-tab-item
              class="mt-2"
            >
              <v-card-title>
                Prescribed Medications:
              </v-card-title>
              <v-card-text>
                <v-list>
                  <v-list-item
                    v-for="item,index in parseRx"
                    :key="item"
                  >
                    <v-list-item-content>
                      {{ index+1 }}:  {{ item }}
                      <!-- <v-list-item-title>{{ this.visitData[0].visitData.rx }}</v-list-item-title> -->
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-tab-item>
            <v-tab-item
              class="mt-2"
            >
              <v-card-title>
                Samples available from PharmaSmart
              </v-card-title>
              <v-data-table
                dense
                :headers="headers"
                :items="visitData[2]"
                :items-per-page="5"
              ></v-data-table>
              <v-card-text>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useVisitStore } from '@/stores/Visit'

export default {
    name: 'VisitCard',
    setup() {
        const visitStore = useVisitStore()

        return {
            visitStore,
            tab: null,
            tab2: null,
            items: ['Visit', 'Med', 'Samples'],
            text: 'Although cards can support multiple actions, UI controls, and an overflow menu, use restraint and remember that cards…',
        }
    },
    data() {
        return {
            headers: [
                {
                    text: 'Sample Brand',
                    value: 'brand',
                },
                {
                    text: 'Product Name',
                    value: 'product',
                },
            ],
        }
    },
    computed: {
        parseRx() {
            //   const transform = this.visitData[0].visitData.rx.split('\n').map(item => item.split(':'))

            const transform = this.visitData[0].visitData.rx.split('\n')

            return transform
        },
        visitData() {
            let visit = []
            visit = [
                {
                    visitData: this.visitStore.visitData,
                    patientData: this.visitStore.patientData,
                },
                this.visitStore.visitData.rx,
                this.visitStore.visitData.samples,
            ]

            return visit
        },
        transmissionStatus() {
            return this.visitStore.transmissionStatus
        },
    },
}
</script>

<style lang="scss" scoped>
.tab-index {
  position: relative;
  z-index: 1;
}
</style>
