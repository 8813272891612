<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="12"
      >
        <card-navigation></card-navigation>
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="12"
      >
        <custom-timeline-card></custom-timeline-card>
      </v-col>
    </v-row>
    <router-view></router-view>
  </div>
</template>

<script>
import { useVisitStore } from '@/stores/Visit.js'
import CardNavigation from '@/components/UI/CardNavigation.vue'
import CustomTimelineCard from '@/components/UI/CustomTimelineCard.vue'
import { getInitials } from '@/util/functions/index.js'

export default {
    name: 'Home',
    components: {
        CardNavigation,
        CustomTimelineCard,
    },
    setup() {
        const visitStore = useVisitStore()

        return {
            visitStore,
        }
    },
    data() {
        return {}
    },
    computed: {
        patientInitials() {
            return getInitials(this.visitStore.patientData.name)
        },
    },
    mounted() {
    // this.getFromLocalStorage()
    },
    methods: {
        getFromLocalStorage() {
            const form = JSON.parse(localStorage.getItem('form'))
            this.visitStore.setVisit({ id: form.id, dob: form.dob })
        },
    },
}
</script>
